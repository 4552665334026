import antdData from 'antd/lib/locale-provider/zh_TW'
import localeData from 'react-intl/locale-data/zh'

const messages = {
  'topBar.issuesHistory': 'Issues 歷史',
  'topBar.projectManagement': '專案管理',
  'topBar.typeToSearch': '搜尋...',
  'topBar.buyNow': '立即購買',
  'topBar.bitcoin': '比特幣',
  'topBar.profileMenu.hello': '你好',
  'topBar.profileMenu.billingPlan': '結帳計劃',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': '電話',
  'topBar.profileMenu.editProfile': '編輯個人資料',
  'topBar.profileMenu.logout': '登出',
  'topBar.profileMenu.salesLv': '業務等級',
}

export default {
  locale: 'zh-TW',
  antdData,
  localeData,
  messages,
}
