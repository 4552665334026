export function getLeftMenuDataAdmin() {
  // 左選單
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      divider: true,
    },
    // {
    //   title: '供應商管理',
    //   key: 'vends',
    //   icon: 'icmn icmn-menu',
    //   url: '/vends',
    // },
    {
      title: '商品管理',
      key: 'mats',
      icon: 'icmn icmn-truck',
      url: '/mats',
    },
    {
      title: '訂單明細',
      key: 'sos',
      icon: 'icmn icmn-list2',
      url: '/sosItems',
    },
    {
      title: '客戶明細',
      key: 'custs',
      icon: 'icmn icmn-user-tie',
      url: '/custs',
    },
    {
      divider: true,
    },
    {
      title: '帳號管理',
      key: 'admins',
      icon: 'icmn icmn-users',
      url: '/admins',
    },
    {
      title: '參數管理',
      key: 'params',
      icon: 'icmn icmn-wrench',
      url: '/params',
    },
    // {
    //   title: '庫存異動',
    //   key: 'docs',
    //   icon: 'icmn icmn-menu',
    //   url: '/docs',
    // },
    // {
    //   title: '模具管理',
    //   key: 'molds',
    //   icon: 'icmn icmn-menu',
    //   url: '/tools/molds',
    // },
    // {
    //   title: '治具管理',
    //   key: 'jgs',
    //   icon: 'icmn icmn-menu',
    //   url: '/tools/jgs',
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: '採購管理',
    //   key: 'pos',
    //   icon: 'icmn icmn-menu',
    //   children: [
    //     {
    //       title: '採購管理',
    //       key: 'pos',
    //       url: '/pos',
    //     },
    //     {
    //       title: '明細',
    //       key: 'posItems',
    //       url: '/posItems',
    //     },
    //     {
    //       title: '採購請款',
    //       key: 'poInvcs',
    //       url: '/poInvcs',
    //     },
    //   ],
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: '工單管理',
    //   key: 'wos',
    //   icon: 'icmn icmn-menu',
    //   url: '/wos',
    // },
    // {
    //   title: '出貨管理',
    //   key: 'delivs',
    //   icon: 'icmn icmn-menu',
    //   url: '/delivs',
    // },
    // {
    //   title: '請款管理',
    //   key: 'invcs',
    //   icon: 'icmn icmn-menu',
    //   url: '/invcs',
    // },
    // {
    //   divider: true,
    // },
  ]
}
export function getTopMenuDataAdmin() {
  // 上選單
  return []
}
