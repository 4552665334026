export function getLeftMenuDataSales() {
  // 左選單
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      divider: true,
    },
    {
      title: '下訂單',
      key: 'sosCreate',
      icon: 'bi bi-cart-plus',
      url: '/sos/create',
    },
    {
      title: '訂單明細',
      key: 'sos',
      icon: 'bi bi-list-ul',
      url: '/sosItems',
    },
    {
      divider: true,
    },
    {
      title: '客戶建檔',
      key: 'custsCreate',
      icon: 'bi bi-person-plus-fill',
      url: '/custs/create',
    },
    {
      title: '客戶管理',
      key: 'custs',
      icon: 'bi bi-file-earmark-person-fill',
      url: '/custs',
    },
    {
      divider: true,
    },
    {
      title: '經銷商佣金',
      key: 'money',
      icon: 'bi bi-coin',
      url: '/money',
    },
    // {
    //   title: '物料管理',
    //   key: 'mats',
    //   icon: 'icmn icmn-menu',
    //   url: '/mats',
    // },
    // {
    //   title: '庫存異動',
    //   key: 'docs',
    //   icon: 'icmn icmn-menu',
    //   url: '/docs',
    // },
    // {
    //   title: '模具管理',
    //   key: 'molds',
    //   icon: 'icmn icmn-menu',
    //   url: '/tools/molds',
    // },
    // {
    //   title: '治具管理',
    //   key: 'jgs',
    //   icon: 'icmn icmn-menu',
    //   url: '/tools/jgs',
    // },
    // {
    //   divider: true,
    // },
    // {
    //   title: '訂單管理',
    //   key: 'sos',
    //   icon: 'icmn icmn-menu',
    //   children: [
    //     {
    //       title: '訂單管理',
    //       key: 'sos',
    //       icon: 'icmn icmn-menu',
    //       url: '/sos',
    //     },
    //     {
    //       title: '明細',
    //       key: 'sosItems',
    //       icon: 'icmn icmn-menu',
    //       url: '/sosItems',
    //     },
    //   ],
    // },
    // {
    //   title: '工單管理',
    //   key: 'wos',
    //   icon: 'icmn icmn-menu',
    //   url: '/wos',
    // },
    // {
    //   title: '出貨管理',
    //   key: 'delivs',
    //   icon: 'icmn icmn-menu',
    //   url: '/delivs',
    // },
  ]
}
export function getTopMenuDataSales() {
  // 上選單
  return []
}
