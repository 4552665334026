import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/dashboard',
    component: loadable(() => import('pages/erp/dashboard')),
    exact: true,
  },
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // 客戶管理
  {
    path: '/custs',
    component: loadable(() => import('pages/erp/custs/readlist')),
    exact: true,
  },
  {
    path: '/custs/create',
    component: loadable(() => import('pages/erp/custs/create')),
    exact: true,
  },
  {
    path: '/custs/:id',
    component: loadable(() => import('pages/erp/custs/read')),
    exact: true,
  },
  {
    path: '/custs/:id/update',
    component: loadable(() => import('pages/erp/custs/update')),
    exact: true,
  },

  // 商品管理
  {
    path: '/mats',
    component: loadable(() => import('pages/erp/mats/readlist')),
    exact: true,
  },
  {
    path: '/mats/create',
    component: loadable(() => import('pages/erp/mats/create')),
    exact: true,
  },
  {
    path: '/mats/:id',
    component: loadable(() => import('pages/erp/mats/read')),
    exact: true,
  },
  {
    path: '/mats/:id/update',
    component: loadable(() => import('pages/erp/mats/update')),
    exact: true,
  },

  // 經銷商佣金
  {
    path: '/money',
    component: loadable(() => import('pages/erp/money')),
    exact: true,
  },

  // 訂單管理
  {
    path: '/sosItems',
    component: loadable(() => import('pages/erp/sos/readlistItems')),
    exact: true,
  },
  {
    path: '/sos/create',
    component: loadable(() => import('pages/erp/sos/create')),
    exact: true,
  },

  // 後台管理者
  {
    path: '/admins',
    component: loadable(() => import('pages/erp/admins/readlist')),
    exact: true,
  },
  {
    path: '/admins/create',
    component: loadable(() => import('pages/erp/admins/create')),
    exact: true,
  },
  {
    path: '/admins/:id',
    component: loadable(() => import('pages/erp/admins/read')),
    exact: true,
  },
  {
    path: '/admins/:id/update',
    component: loadable(() => import('pages/erp/admins/update')),
    exact: true,
  },

  // 參數管理
  {
    path: '/params',
    component: loadable(() => import('pages/erp/params/readlist')),
    exact: true,
  },
  {
    path: '/params/create',
    component: loadable(() => import('pages/erp/params/create')),
    exact: true,
  },
  {
    path: '/params/:id',
    component: loadable(() => import('pages/erp/params/read')),
    exact: true,
  },
  {
    path: '/params/:id/update',
    component: loadable(() => import('pages/erp/params/update')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
