export function getLeftMenuDataManufacture() {
  // 左選單
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      divider: true,
    },
    {
      title: '物料管理',
      key: 'mats',
      icon: 'icmn icmn-menu',
      url: '/mats',
    },
    {
      title: '庫存異動',
      key: 'docs',
      icon: 'icmn icmn-menu',
      url: '/docs',
    },
    {
      title: '模具管理',
      key: 'molds',
      icon: 'icmn icmn-menu',
      url: '/tools/molds',
    },
    {
      title: '治具管理',
      key: 'jgs',
      icon: 'icmn icmn-menu',
      url: '/tools/jgs',
    },
    {
      divider: true,
    },
    {
      title: '工單管理',
      key: 'wos',
      icon: 'icmn icmn-menu',
      url: '/wos',
    },
  ]
}
export function getTopMenuDataForM() {
  // 上選單
  return []
}
