export function getLeftMenuDataPurchasing() {
  // 左選單
  return [
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'icmn icmn-home',
      url: '/dashboard',
    },
    {
      divider: true,
    },
    {
      title: '供應商管理',
      key: 'vends',
      icon: 'icmn icmn-menu',
      url: '/vends',
    },
    {
      divider: true,
    },
    {
      title: '物料管理',
      key: 'mats',
      icon: 'icmn icmn-menu',
      url: '/mats',
    },
    {
      title: '庫存異動',
      key: 'docs',
      icon: 'icmn icmn-menu',
      url: '/docs',
    },
    {
      title: '模具管理',
      key: 'molds',
      icon: 'icmn icmn-menu',
      url: '/tools/molds',
    },
    {
      title: '治具管理',
      key: 'jgs',
      icon: 'icmn icmn-menu',
      url: '/tools/jgs',
    },
    {
      divider: true,
    },
    {
      title: '採購管理',
      key: 'pos',
      icon: 'icmn icmn-menu',
      children: [
        {
          title: '採購管理',
          key: 'pos',
          url: '/pos',
        },
        {
          title: '明細',
          key: 'posItems',
          url: '/posItems',
        },
        {
          title: '採購請款',
          key: 'poInvcs',
          url: '/poInvcs',
        },
      ],
    },
  ]
}
export function getTopMenuDataPurchasing() {
  // 上選單
  return []
}
